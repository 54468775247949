<template>
  <div class="common-copyright-container">
    <h2>Privacy Policy</h2>
    <div class="content">
      <p>
        <strong>InspiringHK Sports Foundation Company Limited (the “Foundation”)</strong>
      </p>
      <ol>
        <li v-for="(item, index) in list" :key="index">
          {{ index + 1 + '. ' }}<strong>{{ item.tit }}</strong>
          <div style="margin-left: 1em" v-html="sit.txt" v-for="(sit, spt) in item.sites" :key="spt"></div>
          <br />
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          tit: 'General',
          sites: [
            {
              txt: 'The Foundation is committed to protecting the personal data provided by you and it is the Foundation’s policy to ensure its policies in relation to the collection, use, transfer, retention and access of personal data comply with the requirements of the Personal Data (Privacy) Ordinance, Cap.486 .',
            },
          ],
        },
        {
          tit: 'Types of Personal Data Collected',
          sites: [
            {
              txt: 'For the purpose of provision of charity services by the Foundation, you may be requested to provide certain personal data including but not limited to the name, contact details (including but not limited to telephone number, fax number, email and/or correspondence address) and copies of any documents which may contain your personal data. Provision of personal data is on a voluntary basis.',
            },
          ],
        },
        {
          tit: 'Use of Personal Data',
          sites: [
            {
              txt: 'The personal data provided by you may be used by the Foundation for the purposes of:',
            },
            { txt: 'a) provision of charity services by the Foundation;' },
            {
              txt: 'b) external communication of the charity services provided by the Foundation (including but not limited to charity programs, fund raising activities and volunteer services etc.)',
            },
            { txt: 'c) distribution of the Foundation’s newsletters; and' },
            {
              txt: 'd) compliance with laws, rules and regulations applicable to the Foundation from time to time.',
            },
          ],
        },
        {
          tit: 'Use of Personal Data for External Communication',
          sites: [
            {
              txt: 'The Foundation intends to use the name and contact details (including telephone number, fax number, email and/or correspondence address) of you for external communication of the charity services (including but not limited to charity programs, fund raising activities and volunteer services etc.), provided by the Foundation. The personal data provided by you will not be disclosed by us to any other third party without your prior consent.',
            },
          ],
        },
        {
          tit: 'Request to Cease the Use of Personal Data and Erasure of Personal Data',
          sites: [
            {
              txt: "You have the right, at any time without charge, to request the Foundation to cease to use your personal data for external communication. Additionally, the Foundation may take reasonable and practicable steps to erase your personal data where the data is no longer required for the purpose for which the data was used, unless such erasure is prohibited by law or it is in the public interest not to have the data erased. You may write to info@inspiringhk.org or Room 901-903, Prosperity Millennia Plaza, 663 King's Road, Quarry Bay for making such request.",
            },
          ],
        },
        {
          tit: 'Security and Retention of Personal Data',
          sites: [
            {
              txt: 'The Foundation has implemented internal control measures to ensure the security of personal data and to prevent unauthorized or accidental access, process, erasure, loss or use of personal data.',
            },
          ],
        },
        {
          tit: 'Access to and Correction of Personal Data',
          sites: [
            {
              txt: "You have the right to access and/or amend your personal data, data, including the right to request the Foundation to cease to use your personal data for external communication. You may at any time write to the Development Officer of the Foundation, at info@inspiringhk.org or Room 901-903, Prosperity Millennia Plaza, 663 King's Road, Quarry Bay. Please note that the Foundation has the right to charge a reasonable fee for the processing of any data access request.",
            },
          ],
        },
        {
          tit: 'Enquiries',
          sites: [
            {
              txt: "Enquiries concerning your personal data provided, including making access and corrections, shall be addressed in writing to the Development Officer of the Foundation, at info@inspiringhk.org or Room 901-903, Prosperity Millennia Plaza, 663 King's Road, Quarry Bay",
            },
          ],
        },
      ],
    }
  },
}
</script>
